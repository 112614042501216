import {
  MILESTONE_TYPES,
} from '../../../shared/utils/constants/shipments';

const DELIVERY_MANAGEMENT_DAYS_PER_RANGE = 5;
const DELIVERY_MANAGEMENT_MAX_DAY_RANGE = 6; // total 30 days
const DELIVERY_MANAGEMENT_SHIPMENTS_PER_PAGE = 8;
const DELIVERY_MANAGEMENT_SHIPMENTS_PER_PAGE_MAX = 16;
const DELIVERY_MANAGEMENT_MILESTONE_TYPES = [
  { key: MILESTONE_TYPES.arrival },
  { key: MILESTONE_TYPES.customs_clearance_arrival },
  {
    key: MILESTONE_TYPES.delivery,
    subKey: 'drayageReservationStatus',
  },
];
const DELIVERY_MANAGEMENT_MILESTONE_UNKNOWN = 'unknown'; // Can be anything, just distinct with other statuses

const STORAGE_DASHBOARD_ASSIGNEE = 'dashboard-assignee';

const ALERT_PANEL = {
  MINIMUM_ITEMS: 2,
  INTERVAL: 10,
  MAXIMUM_ITEMS: 10,
};

const ALERT_COUNTER_FILTER_SELECTION = ['delay_critical', 'critical'];

const CUSTOM_ASSIGNED_TO_OPTIONS = {
  allAssignees: 'allAssignees',
  noAssignees: 'noAssignee',
};

const CUSTOM_DASHBOARD_ASSIGNED_TO_TYPENAME = 'CustomDashboardAssigneeToTypename';
const NO_ASSIGNEE = {
  id: CUSTOM_ASSIGNED_TO_OPTIONS.noAssignees,
  __typename: CUSTOM_DASHBOARD_ASSIGNED_TO_TYPENAME,
};
const ALL_ASSIGNEES = {
  id: CUSTOM_ASSIGNED_TO_OPTIONS.allAssignees,
  __typename: CUSTOM_DASHBOARD_ASSIGNED_TO_TYPENAME,
};

const INFORMATION_PANEL = {
  MINIMUM_ITEMS: 2,
  INTERVAL: 10,
  MAXIMUM_ITEMS: 10,
};

const PORT_TYPES = {
  port: 'port',
  transship: 'transship',
  portOfCall: 'portOfCall',
};

const SHIPMENT_MANAGEMENT_DAYS_PER_RANGE = 5;
const SHIPMENT_MANAGEMENT_MAX_DAY_RANGE = 6; // total 30 days
const SHIPMENT_MANAGEMENT_ITEMS_PER_PAGE = 3;

// Based on animation speed set here: src/assets/styles/_animations.scss
const MAP_RESIZE_DELAY = 300;

export {
  ALERT_PANEL,
  ALERT_COUNTER_FILTER_SELECTION,
  CUSTOM_DASHBOARD_ASSIGNED_TO_TYPENAME,
  CUSTOM_ASSIGNED_TO_OPTIONS,
  DELIVERY_MANAGEMENT_DAYS_PER_RANGE,
  DELIVERY_MANAGEMENT_MAX_DAY_RANGE,
  DELIVERY_MANAGEMENT_MILESTONE_TYPES,
  DELIVERY_MANAGEMENT_MILESTONE_UNKNOWN,
  DELIVERY_MANAGEMENT_SHIPMENTS_PER_PAGE_MAX,
  DELIVERY_MANAGEMENT_SHIPMENTS_PER_PAGE,
  INFORMATION_PANEL,
  MAP_RESIZE_DELAY,
  NO_ASSIGNEE,
  ALL_ASSIGNEES,
  PORT_TYPES,
  SHIPMENT_MANAGEMENT_DAYS_PER_RANGE,
  SHIPMENT_MANAGEMENT_ITEMS_PER_PAGE,
  SHIPMENT_MANAGEMENT_MAX_DAY_RANGE,
  STORAGE_DASHBOARD_ASSIGNEE,
};
