import { TFunction } from 'react-i18next';

import { CurrencyEnum, UserInterface } from '@/__codegen__/graphql';
import { ValueOf } from '@/shared/utils/typescript';

export const formatDayString = (numberOfDays: number, t: TFunction<'translation'>) => t('translation:general.units.days', { s: numberOfDays === 1 ? '' : 's' });

/**
 * Formatting the a number values with respective commas e.g. "1,200"
 * @param number the monetary number you want to format
 * @param currency the currency which determines the locale, this is optional
 */
export const formatCurrencyNumberToString = (
  number: number,
  currency: ValueOf<typeof CurrencyEnum> | null = null,
) => {
  switch (currency) {
    case CurrencyEnum.Jpy:
      return number?.toLocaleString('ja-JP');

    case CurrencyEnum.Usd:
    // Divide number by 100 if USD
      return (number / 100)?.toLocaleString('en-US');

    default:
    // Uses the default locale detected
      return number?.toLocaleString();
  }
};

/**
 * TODO: DEPRECATED, replace instances of this with generateCustomerEntityName
 * Generate the full name of a customer user, for now it is taking the Japanese format
 * lastName -> firstName.
 *
 * TODO: add ability to differentiate with english names first name -> last name
 * @param t the i18n object that takes care of translations
 * @param customerUser the customer user object OR null if there is no-one
 * @param includeBrackets include brackets for no assignee
 */
export const generateCustomerUserFullName = (
  t: TFunction<'shipment'>,
  customerUser: Pick<UserInterface, 'firstName' | 'lastName' | 'name'> | null = null,
  includeBrackets = true,
) => {
  if (customerUser?.firstName || customerUser?.lastName) {
    return `${customerUser?.lastName || ''} ${customerUser?.firstName || ''}`;
  }

  if (includeBrackets) {
    return `(${t('shipment:assignCustomerUser.noAssignee')})`;
  }

  return t('shipment:assignCustomerUser.noAssignee');
};

/**
 * @param t the i18n object that takes care of translations
 * @param customerEntity the customer entity object OR null if there is no-one
 */
export const generateCustomerEntityName = (
  t: TFunction<'shipment'>,
  customerEntity: Pick<UserInterface, 'firstName' | 'lastName' | 'name'> | null = null,
  includeBrackets = true,
) => {
  if (customerEntity?.firstName || customerEntity?.lastName || customerEntity?.name) {
    return `${customerEntity?.lastName || customerEntity?.name || ''} ${customerEntity?.firstName || ''}`;
  }

  if (includeBrackets) {
    return `(${t('shipment:assignCustomerUser.noAssignee')})`;
  }

  return t('shipment:assignCustomerUser.noAssignee');
};

// Format unit for billItems and pricingItems
export const formatUnit = (unit: string) => {
  if (unit
    && !unit.includes('{')
    && !unit.includes('}')) {
    return unit;
  }
  return '';
};

export const formatDecimals = (
  value: number, decimals = 3,
) => parseFloat(Number(value).toFixed(decimals));

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * Check whether an object is empty or not
 * https://stackoverflow.com/a/32108184
 * @param obj
 */
export const isObjectIsEmpty = (
  obj?: Record<string, any>,
) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

/**
 * Parse a multidimensional object i.e. remove properties depending
 * on the given parse condition AND flatten to one level if required
 *
 * NOTE: does not flatten arrays in the object!
 *
 * Based off this: https://stackoverflow.com/a/55251598
 * @param obj
 * @param parseFn pass a function that parses object property data
 * to check if it's worth adding to the flattened object
 * @param flatten flag if we want to flatten or not
 */
export const parseObject = (
  obj: Record<string, any>,
  parseFn: (property: any) => boolean,
  flatten = false,
) => {
  const parsed = {} as Record<string, any>;

  Object.keys(obj).forEach((key) => {
    const property = obj[key];

    if (parseFn && parseFn(property)) {
      // Recursively parse objects, BUT not arrays
      if (typeof property === 'object' && property.constructor !== Array) {
        if (flatten) {
          Object.assign(parsed, parseObject(property, parseFn, flatten));
        } else {
          parsed[key] = {};
          Object.assign(parsed[key], parseObject(property, parseFn, flatten));
        }
      } else {
        parsed[key] = property;
      }
    }
  });

  return parsed;
};

/**
 * From https://stackoverflow.com/questions/6229197/how-to-know-if-two-arrays-have-the-same-values
 * Compare two arrays composed of primitive types (integers, strings).
 * @param array1
 * @param array2
 * @returns
 */
export const compareArraysOfPrimitives = (
  array1: Array<string | number | boolean>,
  array2: Array<string | number | boolean>,
) => {
  if (array1?.length >= 0 && array2?.length >= 0 && array1?.length === array2?.length) {
    // .concat() to not mutate arguments
    const arr1 = array1.concat().sort();
    const arr2 = array2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }
  return false;
};

/**
 * Generates an unique random id
 */
export const generateRandomId = () => {
  const random = Math.random().toString(16).slice(2);
  const time = (new Date()).getTime();
  return `uid${time}${random}`;
};
