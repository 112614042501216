import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorContainer from '../../../components/Errors/ErrorContainer';

const Maintenance = () => {
  const { t } = useTranslation(['translation']);

  return (
    <ErrorContainer>
      <p>{t('translation:general.errorMessage.underMaintenance')}</p>
    </ErrorContainer>
  );
};
export default Maintenance;
