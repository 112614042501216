import {
  useContext, useState, useMemo, createContext, ReactNode,
} from 'react';

import { SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY } from '@/shared/utils/constants/sidebar';

type SidebarContextType = {
  isCollapsed: boolean;
  isToggleDisabled: boolean;
  setIsCollapsed: (status: boolean) => void;
  setIsToggleDisabled: (status: boolean) => void;
};
export const SidebarContext = createContext<SidebarContextType | null>(null);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem(SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY) === 'true',
  );

  const [isToggleDisabled, setIsToggleDisabled] = useState(false);

  const className = `fo-app${isCollapsed ? ' sidebar-collapsed' : ''}`;

  const setSidebarIsCollapsed = (status: boolean) => {
    localStorage.setItem(SIDEBAR_COLLAPSE_LOCAL_STORAGE_KEY, `${status}`);
    setIsCollapsed(status);
  };

  const sidebarValues = useMemo(() => ({
    isCollapsed,
    isToggleDisabled,
    setIsCollapsed: setSidebarIsCollapsed,
    setIsToggleDisabled,
  }), [isCollapsed, isToggleDisabled]);

  return (
    <SidebarContext.Provider value={sidebarValues}>
      <div className={className}>
        {children}
      </div>
    </SidebarContext.Provider>
  );
};

export function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }

  return context;
}
