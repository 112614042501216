import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Question } from '@/assets/icons/custom/question.svg';
import { ReactComponent as CogSolid } from '@/assets/icons/LineAwesome/cog-solid.svg';
import { ReactComponent as ShipSolid } from '@/assets/icons/LineAwesome/ship-solid.svg';
import { SidebarMenu, MenuItem } from '@/shared/components/Sidebar/SidebarMenu/SidebarMenu';
import { AppState } from '@/shared/redux/AppState';
import { useLogDirectAccessSidebarClick } from '@/shared/utils/hooks/ActivityLogging/useLogDirectAccessSidebarClick';

export const SidebarMenuContainer = () => {
  const { t } = useTranslation(['translation']);
  const { pathname } = useLocation();

  const teamId = useSelector((state: AppState) => state.user.teamId);
  const [pushUIEvent] = useLogDirectAccessSidebarClick(teamId);

  /**
   * Handler that dispatches direct access UI event
   * when user clicks on menu items
   */
  const handleOnClick = useCallback((link) => {
    pushUIEvent(link);
  }, [pushUIEvent]);

  const menuItems: MenuItem[][] = [
    [ // Menu items above the divider
      {
        type: 'link',
        title: t('translation:navigation.shipments'),
        to: {
          pathname: '/shipments',
          state: { from: 'sidebar' },
        },
        icon: <ShipSolid />,
        isActive: () => pathname.includes('/shipment'),
        onClick: () => handleOnClick('shipment'),
      },
    ], [ // Menu items below the divider
      {
        type: 'link',
        title: t('translation:navigation.setting'),
        to: '/settings',
        icon: <CogSolid />,
      },
      {
        type: 'button',
        title: t('translation:navigation.support'),
        icon: <Question />,
      },
    ],
  ];

  return <SidebarMenu menuItems={menuItems} />;
};
