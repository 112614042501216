import { createTheme } from '@mui/material/styles';

const frontTheme = createTheme({
  // Using the mixed font of both Montserrat and Noto Sane JP
  // for corresponding glyphs
  typography: {
    fontFamily: [
      'Noto Sans JP',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Segoe UI',
      'Helvetica Neue',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
  colors: {
    blue: {
      1: '#EFF3FF',
      2: '#DDE6FE',
      3: '#C4CFF9',
      4: '#96A4F1',
      5: '#6572E9',
      6: '#3A49D1',
      7: '#0D1597',
      8: '#000665',
    },
    base: {
      1: '#FFFFFF',
      2: '#F8F8F8',
      3: '#EEEEEE',
      4: '#DEE0E0',
      5: '#A1A1A1',
      6: '#757575',
      7: '#3A3E46',
      8: '#111111',
    },
    green: {
      1: '#EAFCE9',
      2: '#BFF7BB',
      3: '#95F28E',
      4: '#2AE61E',
      5: '#22B818',
      6: '#0F7D08',
      7: '#054D00',
      8: '#032F00',
    },
    red: {
      1: '#FFF7F5',
      3: '#FBEBE7',
      5: '#D53612',
      7: '#80200B',
    },
    yellow: {
      1: '#FFFCF5',
      3: '#FAEDCC',
      5: '#E8A701',
      7: '#805C01',
    },
    orange: {
      1: '#FFE1BF',
      5: '#FF8600',
    },
    purple: {
      1: '#D4B8F5',
      5: '#923BF5',
    },
  },
  palette: {
    primary: {
      main: '#000665',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#2AE61E',
      contrastText: '#FFF',
    },
    neutral: {
      main: '#FFF',
      contrastText: '#000665',
    },
    error: {
      main: '#D53612',
    },
    // Todo: Implement better way to add colors to the palette.
    baseGreySix: {
      main: '#3A3E46',
    },
  },
});

// see: https://mui.com/blog/mui-core-v5/#dynamic-props
declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    baseGreySix: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    baseGreySix: PaletteOptions['primary'];
  }
  interface Theme {
    colors: {
      base: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      green: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      blue: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      red: {
        1: string;
        3: string;
        5: string;
        7: string;
      };
      yellow: {
        1: string;
        3: string;
        5: string;
        7: string;
      };
      orange: {
        1: string;
        5: string;
      };
      purple: {
        1: string;
        5: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: {
      base: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      green: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      blue: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
        6: string;
        7: string;
        8: string;
      };
      red: {
        1: string;
        3: string;
        5: string;
        7: string;
      };
      yellow: {
        1: string;
        3: string;
        5: string;
        7: string;
      };
      orange: {
        1: string;
        5: string;
      };
      purple: {
        1: string;
        5: string;
      };
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export default frontTheme;
