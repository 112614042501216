export const NOTIFICATION_TYPE = {
  alert: 'alert',
  announcement: 'announcement',
} as const;

export const ALERT_STATUS_TYPE = {
  delay_critical: 'delay_critical',
  critical: 'critical',
  delay_warning: 'delay_warning',
  warning: 'warning',
} as const;

export const NOTIFICATION_CONTENT_TYPE = {
  alert: 'alert',
  eta_update: 'eta_update',
  car_contact_info: 'car_contact_info',
  milestone_complete: 'milestone_complete',
  bill_count: 'bill_count',
  quote_request_complete: 'quote_request_complete',
} as const;

export const NOTICE_LIST_PER_QUERY = 30 as const;

export const LAST_OPENED_AT_KEY = 'noticeListLastOpenedAt' as const; // Used for local storage
