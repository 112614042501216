import './ErrorContainer.scss';
import React from 'react';
import PropTypes from 'prop-types';

import shippioNavyLogo from '../../assets/images/logo-navy.png';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const ErrorContainer = ({ children }) => (
  <div className="error-container">
    <img src={shippioNavyLogo} height="50" alt="shippio-logo" />
    { children }
  </div>
);

ErrorContainer.propTypes = propTypes;

export default ErrorContainer;
