import { graphql } from '@/__codegen__';
import { GetCurrentUserLoginInfoQuery } from '@/__codegen__/graphql';

export const GetCurrentUserLoginInfo = graphql(`
    query GetCurrentUserLoginInfo {
      currentUserResolver {
        loginUserType
        loginUserCompanyType
      }
    }
`);

export type GetCurrentUserLoginInfoItem = GetCurrentUserLoginInfoQuery['currentUserResolver'];
