import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ThemeTooltip from '@/shared/components/common/ThemeTooltip/ThemeTooltip';
import { useSidebar } from '@/shared/context/SidebarContext';
import { resetShipmentSidebarPreviousValue } from '@/shared/redux/shipments/actions';

export type MenuItem = {
  type: 'link' | 'button';
  title: string;
  to?: string | {
    pathname: string;
    state: {
      from: string;
    };
  };
  disabled?: boolean;
  icon: React.ReactNode;
  isActive?: () => boolean;
  onClick?: () => void;
};

type Props = {
  menuItems: MenuItem[][];
}

export const SidebarMenu = ({ menuItems }: Props) => {
  const dispatch = useDispatch();
  const { isCollapsed } = useSidebar();

  const listItemsGenerator = (items: MenuItem[]) => items.map(({
    type, title, icon, disabled, to, isActive, onClick,
  }) => {
    if (type === 'button') {
      return (
        <ThemeTooltip arrow title={isCollapsed ? title : ''} placement="right" key={title}>
          <ListItemButton
            component="a"
            className="sidebar__nav-list__item"
            onClick={() => window.open('https://support.shippio.io/hc/ja', '_blank')}
            disabled={disabled}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </ListItemButton>
        </ThemeTooltip>
      );
    }

    return (
      <ThemeTooltip arrow title={isCollapsed ? title : ''} placement="right" key={title}>
        <ListItem
          // React router dom link component does not have disabled capability
          // https://github.com/ReactTraining/react-router/issues/1082#issuecomment-160348163
          className={`sidebar__nav-list__item${disabled ? ' sidebar__nav-list__item--is-disabled' : ''}`}
          component={NavLink as React.ElementType}
          to={to}
          isActive={isActive}
          onClick={() => {
            dispatch(resetShipmentSidebarPreviousValue());
            if (onClick) {
              onClick();
            }
          }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{title}</ListItemText>
          <div className="sidebar__active-border" />
        </ListItem>
      </ThemeTooltip>
    );
  });

  const listItemsAbove = listItemsGenerator(menuItems[0]);
  const listItemsBelow = listItemsGenerator(menuItems[1]);

  return (
    <>
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsAbove}
      </List>
      <Divider className="sidebar__divider" />
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsBelow}
      </List>
    </>
  );
};
