import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginUserCompanyTypeEnum, LoginUserTypeEnum } from '@/__codegen__/graphql';

export type CurrentUserState = {
  loginUserType: LoginUserTypeEnum | null
  loginUserCompanyType: LoginUserCompanyTypeEnum | null
};

// TODO: unify `CurrentUserState` and `UserState` since both are "current session" data
const initialState: CurrentUserState = {
  loginUserType: null,
  loginUserCompanyType: null,
};

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      action: PayloadAction<CurrentUserState>,
    ) => ({
      ...state,
      loginUserType: action.payload.loginUserType,
      loginUserCompanyType: action.payload.loginUserCompanyType,
    }),
  },
});

export const { setCurrentUser } = currentUserSlice.actions;
