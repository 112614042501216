import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { allowUIEvents } from '@/shared/redux/middleware/allowUIEvents';
import { sharedReducers, devTools } from '@/shared/redux/store';

export const store = configureStore({
  reducer: combineReducers(sharedReducers),
  middleware: (getDefault) => getDefault({ serializableCheck: false }).concat([
    allowUIEvents,
  ]),
  devTools,
});

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
