import { Middleware } from '@reduxjs/toolkit';

import { Auth } from '@/shared/utils/auth/auth';
import { LoginUserRole } from '@/shared/utils/auth/typings';

import { UIEVENTS_PUSH } from '../uiEvents/types';

/**
 * Middleware to stop any UI events being pushed for Internal Mode.
 */
export const allowUIEvents: Middleware = () => (next) => (action) => {
  if (action?.type === UIEVENTS_PUSH) {
    // Prevent UI events from being pushed for Internal Mode
    const isStaff = Auth.getAuthFromCookie().role === LoginUserRole.Staff;
    if (isStaff) {
      return null;
    }
  }

  return next(action);
};
