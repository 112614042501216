import { LoggableUIEvent, LoggableUIEventParams } from './loggableUIEvent';
import * as types from './types';

/**
 * Create action to push event onto `uiEvents` stack
 */
export const pushEvent = (event: LoggableUIEventParams) => ({
  type: types.UIEVENTS_PUSH,
  payload: { event: new LoggableUIEvent(event) },
});

/**
 * Create action to remove single event in `uiEvents` stack
 * @param id ID of event to remove from state
 * @returns Action
 */
export const removeEvent = (id: string) => ({
  type: types.UIEVENTS_REMOVE,
  payload: { id },
});
