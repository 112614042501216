import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export type PlaceholderMenuItem = {
  title: string;
  icon: React.ReactNode;
};

type Props = {
  menuItems: PlaceholderMenuItem[][];
}

export const SidebarMenuPlaceholder = ({ menuItems }: Props) => {
  const listItemsGenerator = (items: PlaceholderMenuItem[]) => items.map(({ title, icon }) => (
    <ListItem
      key={title}
      className="sidebar__nav-list__item">
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </ListItem>
  ));

  const listItemsAbove = listItemsGenerator(menuItems[0]);
  const listItemsBelow = listItemsGenerator(menuItems[1]);

  return (
    <>
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsAbove}
      </List>
      <Divider className="sidebar__divider" />
      <List component="nav" aria-label="primary" className="sidebar__nav-list">
        {listItemsBelow}
      </List>
    </>
  );
};
