export const LANGUAGE_OPTIONS = {
  ja: 'ja',
  en: 'en',
} as const;

export const USER_STATUS = {
  invited: 'invited',
  active: 'active',
  deleted: 'deleted',
} as const;
