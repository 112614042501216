import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { GetCurrentUserLoginInfo } from '../gql/query/GetCurrentUserLoginInfo';
import { AppState } from '../redux/AppState';
import { setCurrentUser } from '../redux/currentUserSlice';

type Props = {
  children: React.ReactNode;
}

// TODO: Integrate the process of getting CurrentUser with the one done by AppAuthenticated.
export const CurrentUserProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.currentUser);

  useQuery(GetCurrentUserLoginInfo, {
    onCompleted: (data) => {
      dispatch(setCurrentUser({
        loginUserType: data?.currentUserResolver.loginUserType ?? null,
        loginUserCompanyType: data?.currentUserResolver.loginUserCompanyType ?? null,
      }));
    },
  });

  if (user.loginUserType === null || user.loginUserCompanyType === null) {
    return null;
  }

  return <>{children}</>;
};
