export const ERRORS = {
  chunkLoadError: {
    name: 'ChunkLoadError',
  },
  maintenance: {
    name: 'Maintenance',
    message: 'Under Maintenance',
  },
  threeWayInvalidPermissions: {
    errorCode: 'invalid_permissions',
    targetFunction: {
      threeWayChat: 'threeWayChat',
      threeWayFile: 'threeWayFile',
    },
  },
} as const;
