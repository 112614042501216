import { randomID } from '../../utils/utils';

export const HEADER_KEYS = {
  type: 'X-Log-Type',
  trigger: 'X-View-Trigger',
  options: 'X-View-Options',
  actionOptions: 'X-Action-Options',
};

// 120,000 ms = 2 min * 60 s * 1000 ms
const TIME_TO_EXPIRY = 120000;

export type LoggableUIEventParams = {
   eventTypes: string[];
   trigger: string;
   options?: any;
   actionOptions?: any;
}

/**
 * Holds record of UI event used to log user activity.
 * Provides headers that can be added to GraphQL request via operations
 * `context` property.
 */
export class LoggableUIEvent {
  public types: string[];
  public trigger: string;
  public id: string;
  public expiry: number;
  public options?: any;
  public actionOptions?: any;

  /**
   * @param parameterOption `eventTypes` is an array of strings, as multiple types may
   * be triggered by a single activity. `options` holds options for views events.
   * `actionOptions` holds options for action events.
   */
  constructor({
    eventTypes,
    trigger,
    options,
    actionOptions,
  }: LoggableUIEventParams) {
    this.types = eventTypes;
    this.trigger = trigger;
    this.options = options;
    this.actionOptions = actionOptions;
    this.id = randomID();
    this.expiry = Date.now() + TIME_TO_EXPIRY;
  }

  /**
   * Tests if event is valid, that is, less than 2 minutes old
   */
  get isValid() {
    return Date.now() < this.expiry;
  }

  /**
   * Queries types array for provided type.
   * @param { string } type
   * @returns boolean
   */
  hasType(type: string) {
    return this.types.includes(type);
  }

  /**
   * Queries trigger if matches provided type.
   * @param { string } trigger
   * @returns boolean
   */
  hasTrigger(trigger: string) {
    return this.trigger === trigger;
  }

  /**
   * Converts properties to headers with server-known names.
   * @private Intended for internal use. Use `UIEvent.partialContext()` for adding
   * headers to GraphQL operation context.
   * @returns { {
   *   'X-View-Type': string,
   *   'X-View-Trigger': string,
   *   'X-View-Options'?: string,
   *   'X-Action-Options'?: string;
   * } }
   */
  headers() {
    const base = {
      // [HEADER_KEYS.type]: JSON.stringify(this.types),
      [HEADER_KEYS.type]: this.types.join(';'),
      [HEADER_KEYS.trigger]: this.trigger,
    };

    const serializedHeaderValue = (object: Record<string, string>) => {
      const entries = Object.entries(object);
      const keyValuePairs = entries.map(([k, v]) => `${k}=${v}`);
      return keyValuePairs.join(';');
    };

    if (this.options) {
      base[HEADER_KEYS.options] = serializedHeaderValue(this.options);
    }

    if (this.actionOptions) {
      base[HEADER_KEYS.actionOptions] = serializedHeaderValue(this.actionOptions);
    }

    return base;
  }

  /**
   * Provides easy-to-spread object to spread into GraphQL operation context.
   * Value at `X-View-Options` is of the pattern `"keyOne=valueOne;keyTwo=valueTwo"`
   * @returns { {
   *   optionalHeaders: {
   *     'X-View-Type': string;
   *     'X-View-Trigger': string;
   *     'X-View-Options'?: string;
   *     'X-Action-Options'?: string;
   *   }
   * } }
   */
  partialContext() {
    const optionalHeaders = this.headers();
    return { optionalHeaders };
  }
}
