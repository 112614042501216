import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LAST_OPENED_AT_KEY } from '../utils/constants/notices';
import { getLocalStorageObject } from '../utils/utils';

const storeLastOpenedAtValue = (customerTeamId: string) => {
  // TODO: customerTeam should always exist; investigate and maybe remove this guard!
  if (!localStorage || !customerTeamId) return;

  localStorage.setItem(
    LAST_OPENED_AT_KEY,
    JSON.stringify({
      ...getLocalStorageObject(LAST_OPENED_AT_KEY, {}),
      [customerTeamId]: new Date().toISOString(),
    }),
  );
};

export const noticeListSlice = createSlice({
  name: 'noticeList',
  initialState: {
    isOpen: false,
    unreadCount: 0,
  },
  reducers: {
    setNoticeListOpen: (
      state, action: PayloadAction<{ teamId: string, isOpen: boolean }>,
    ) => {
      const { teamId, isOpen } = action.payload;
      storeLastOpenedAtValue(teamId);

      return {
        ...state,
        isOpen,
        unreadCount: isOpen ? 0 : state.unreadCount,
      };
    },
    setUnreadNoticeCount: (
      state, action: PayloadAction<{ unreadCount: number }>,
    ) => ({
      ...state,
      unreadCount: action.payload.unreadCount,
    }),
  },
});

export const { setNoticeListOpen, setUnreadNoticeCount } = noticeListSlice.actions;
