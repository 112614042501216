import {
  ApolloClient, InMemoryCache,
} from '@apollo/client';

// https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
// UPDATE: https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
// Cache-and-network behavior has changed for AC3. Therefore, to implement similar behavior
// we will make use of next fetch policy and set to cache-first as the default.
const FETCH_POLICY = 'cache-and-network';
const NEXT_FETCH_POLICY = 'cache-first';

export const cache = new InMemoryCache({
  possibleTypes: {
    ContextTypeUnion: ['Shipment', 'QuoteLane'],
  },
  typePolicies: {
    // Warning where quote request details has no id, therefore we need to configure this
    // type to merge for caching to work properly
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#configuring-merge-functions-for-types-rather-than-fields
    QuoteRequestDetails: {
      merge: true,
    },
  },
});

export const apolloClient = new ApolloClient({
  cache,
  // Automatically set all queries to constant FETCH_POLICY so we don't have to manually
  // set it for each query
  // ---> Context into why we set watchQuery, not query
  // React Apollo uses Apollo Client's watchQuery internally, not query. That means
  // the defaultOptions have to be set for watchQuery to be enabled.
  // https://github.com/apollographql/react-apollo/issues/3163
  // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.watchQuery
  defaultOptions: {
    watchQuery: {
      fetchPolicy: FETCH_POLICY,
      nextFetchPolicy: NEXT_FETCH_POLICY,
    },
  },
});
