import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';

const ThemeTooltip = styled(Tooltip)({
  arrow: {
    color: '#3A3E46',
  },
  tooltip: {
    backgroundColor: '#3A3E46',
    fontSize: 11,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default ThemeTooltip;
