export const CHANNEL_LIST_PER_QUERY = 30 as const;

export const CHANNEL_MESSAGE_TYPE = {
  file: 'file',
  text: 'text',
} as const;

export const DRAFT_CHAT_MESSAGES = 'draft-chat-messages' as const;

export const CHAT_ASSIGNEE_SELECTIONS = {
  assignedChats: 'assignedChats',
  allChats: 'allChats',
} as const;

export const STORAGE_ASSIGNED_CHAT = 'storage-assigned-chat' as const;
