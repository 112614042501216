import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TradeDirectionEnum } from '@/__codegen__/graphql';
import { AppState } from '@/shared/redux/AppState';
import { pushEvent } from '@/shared/redux/uiEvents/actions';
import {
  VIEW_SHIPMENT_LIST,
  VIEW_QUOTE_SIMULATION_PATTERN,
} from '@/shared/utils/constants/activity-log-types';
import { useShipmentListActiveView } from '@/shared/utils/hooks/useShipmentListActiveView';

export const useLogDirectAccessSidebarClick = (teamId: string) => {
  const dispatch = useDispatch();

  const tradeDirection: TradeDirectionEnum = useSelector(
    (state: AppState) => state.shipments.activeTradeDirection,
  );
  const [activeView] = useShipmentListActiveView(tradeDirection, teamId);

  /**
   * Push Ui direct access event to redux depending
   * on the link clicked.
   */
  const pushUIEvent = useCallback((link) => {
    let uiEvent = null;

    switch (link) {
      case 'shipment':
        uiEvent = {
          eventTypes: [VIEW_SHIPMENT_LIST.eventType],
          trigger: VIEW_SHIPMENT_LIST.triggers.directAccess,
          options: { view: VIEW_SHIPMENT_LIST.viewOptions[activeView] },
        };
        break;

      case 'quote-tab':
        uiEvent = {
          eventTypes: [VIEW_QUOTE_SIMULATION_PATTERN.eventType],
          trigger: VIEW_QUOTE_SIMULATION_PATTERN.triggers.directAccess,
          options: {
            [VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.key]:
              // sidebar link will never link to quote
              VIEW_QUOTE_SIMULATION_PATTERN.options.viewLayout.values.normal,
          },
        };
        break;

      default:
        break;
    }

    if (uiEvent) {
      dispatch(pushEvent(uiEvent));
    }
  }, [activeView, dispatch]);

  return [pushUIEvent];
};
