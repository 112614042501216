const INVOICE_TABS = {
  invoice: 'invoice',
  anyCargo: 'anyCargo',
};

const ACTIVE_TAB_LOCAL_INVOICES = 'invoice-active-tab';

const INVOICE_STATUS = {
  beforePayment: 'before_payment',
  paid: 'paid',
  overdue: 'overdue',
  unpublished: 'unpublished',
};

const INVOICE_TYPES = {
  replacementInvoice: 'replacement_invoice',
  invoice: 'invoice',
  additionalInvoice: 'additional_invoice',
};

const INVOICE_CURRENCIES = ['JPY', 'USD'];

const INVOICE_LIST_COLUMN_WIDTHS = {
  invoices: 21.8,
  shipmentDetails: 20.3,
  shipmentMisc: 17.2,
  amount: 9.2,
  date: 9.7,
  status: 12.1,
};

const PAGINATION_PER_PAGE = 50;
const BILLABLE_LIST_PAGINATION_PER_PAGE = 100;

const TAX_TYPE = {
  notTaxable: 'not_taxable',
  taxable: 'taxable',
};

const BULK_DOWNLOAD_OPTIONS = {
  pdf: 'pdf',
  excel: 'excel',
};

export {
  INVOICE_TABS,
  INVOICE_STATUS,
  INVOICE_TYPES,
  INVOICE_CURRENCIES,
  INVOICE_LIST_COLUMN_WIDTHS,
  PAGINATION_PER_PAGE,
  BILLABLE_LIST_PAGINATION_PER_PAGE,
  TAX_TYPE,
  BULK_DOWNLOAD_OPTIONS,
  ACTIVE_TAB_LOCAL_INVOICES,
};
