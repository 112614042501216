import {
  get, set, keys, del, clear, createStore,
} from 'idb-keyval';

// Currently Safari can view all of indexedDB in the same domain,
// which cause a bug cannot connect to the correct DB.
// To fix it, create a different DB name for each sub-domain
const subDomain = window.location.hostname.split('.')[0];
let customGetStoreFunc;
function customGetStore() {
  if (!customGetStoreFunc) {
    customGetStoreFunc = createStore(`shippio-${subDomain}`, 'keyval');
  }
  return customGetStoreFunc;
}

export default {
  clear() {
    return clear(customGetStore());
  },
  getItem(key) {
    return get(key, customGetStore());
  },
  setItem(key, value) {
    return set(key, value, customGetStore());
  },
  keys() {
    return keys(customGetStore());
  },
  remove(key) {
    return del(key, customGetStore());
  },
  removeItem(key) {
    return del(key, customGetStore());
  },
};
