import { useTranslation } from 'react-i18next';

import { ReactComponent as Question } from '../../../../assets/icons/custom/question.svg';
import { ReactComponent as CogSolid } from '../../../../assets/icons/LineAwesome/cog-solid.svg';
import { ReactComponent as ShipSolid } from '../../../../assets/icons/LineAwesome/ship-solid.svg';
import { SidebarMenuPlaceholder } from '../../../../shared/components/Sidebar/SidebarMenu/SidebarMenuPlaceholder';

export const SidebarMenuPlaceholderContainer = () => {
  const { t } = useTranslation(['translation']);

  const menuItems = [
    // Menu items above the divider
    [
      {
        title: t('translation:navigation.shipments'),
        icon: <ShipSolid />,
      },
    ],
    // Menu items below the divider
    [
      {
        title: t('translation:navigation.setting'),
        icon: <CogSolid />,
      },
      {
        title: t('translation:navigation.support'),
        icon: <Question />,
      },
    ],
  ];

  return <SidebarMenuPlaceholder menuItems={menuItems} />;
};
