import { TradeDirectionEnum } from '@/__codegen__/graphql';
import { ActiveView, Filters } from '@/shared/types/shipment';
import { ValueOf } from '@/shared/utils/typescript';

import * as types from './types';

export const editActiveTradeDirection = (
  tradeDirection: TradeDirectionEnum,
) => ({
  type: types.TRADE_DIRECTION_EDIT,
  payload: { activeTradeDirection: tradeDirection },
});

/**
 * option can be e.g. 'import_arrival_date'
 */
export const editSelectedSort = (
  customerTeamId: string, tradeDirection: TradeDirectionEnum, option: string,
) => ({
  type: types.SHIPMENT_SORT_EDIT,
  payload: { customerTeamId, tradeDirection, option },
});

export const editSearchShipment = (searchString: string) => ({
  type: types.SHIPMENT_SEARCH_EDIT,
  payload: { searchString },
});

export const editShipmentListView = (
  customerTeamId: string, tradeDirection: TradeDirectionEnum, view: ActiveView,
) => ({
  type: types.SHIPMENT_VIEW_EDIT,
  payload: { customerTeamId, tradeDirection, view },
});

export const editShipmentFilterOption = (
  customerTeamId: string,
  tradeDirection: TradeDirectionEnum,
  filter: keyof Filters,
  value: ValueOf<Filters>,
) => ({
  type: types.SHIPMENT_FILTER_EDIT,
  payload: {
    customerTeamId, tradeDirection, filter, value,
  },
});

export const editShipmentSidebarPreviousValue = (sidebarWasCollapsed: boolean) => ({
  type: types.SHIPMENT_SIDEBAR_PREVIOUS_EDIT,
  payload: { sidebarWasCollapsed },
});

export const resetShipmentSidebarPreviousValue = () => ({
  type: types.SHIPMENT_SIDEBAR_PREVIOUS_EDIT,
  payload: { sidebarWasCollapsed: null },
});
