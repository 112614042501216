// From https://blog.webdevsimplified.com/2020-10/react-debounce/
import { useEffect, useCallback } from 'react';

export function useDebounceEffect(effect: () => void, deps: ReadonlyArray<any>, delay = 250) {
  // Use callback here is only used to create a debounced callBack
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
