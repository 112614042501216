import { Sidebar } from '@/shared/components/Sidebar/Sidebar';

import { SidebarMenuContainer } from './SidebarMenu/SidebarMenuContainer';
import { SidebarMenuPlaceholderContainer } from './SidebarMenu/SidebarMenuPlaceholderContainer';

export const SidebarContainer = ({ placeholder = false }) => (
  <Sidebar>
    {!placeholder ? <SidebarMenuContainer /> : <SidebarMenuPlaceholderContainer />}
  </Sidebar>
);
