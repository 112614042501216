import {
  Backdrop, CircularProgress, ThemeProvider, StyledEngineProvider,
} from '@mui/material';
import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppFallback from './components/AppFallback/AppFallback';
import ErrorBoundary from '../components/ErrorBoundary';
import frontTheme from '../shared/theme/frontTheme';
import { LANGUAGE_OPTIONS } from '../shared/utils/constants/settings';
import { useAuth } from '../shared/utils/hooks/useAuth';

const AuthenticatedAppForWarehouse = React.lazy(() => import('./AppAuthenticated'));
const AuthenticatedAppForForwarder = React.lazy(() => import('@/AppForwarder/AppAuthenticated'));

function App() {
  const {
    isAuthenticated, isForwarderUser, isSubsidiaryUser, isLoading, error,
  } = useAuth();
  const { i18n } = useTranslation();

  const getApp = () => {
    if (isLoading) {
      // Without this 'isLoading' condition, it loops and returns to the login screen.
      return (
        <Backdrop
          open={isLoading}>
          <CircularProgress />
        </Backdrop>
      );
    }

    if (error) {
      throw error;
    }

    if (isForwarderUser() || isSubsidiaryUser()) {
      return (<AuthenticatedAppForForwarder />);
    }

    return (<AuthenticatedAppForWarehouse />);
  };

  useEffect(() => {
    const { language = LANGUAGE_OPTIONS.ja } = i18n;
    document.documentElement.lang = language;
  }, [i18n, i18n.language]);

  return (
    // This outer div is necessary to keep the notification popups in position
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={frontTheme}>
          <ErrorBoundary>
            <Suspense fallback={<AppFallback authenticated={isAuthenticated} />}>
              {getApp()}
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
